import React, { useEffect } from 'react';

const AdsComponent = () => {
  useEffect(() => {
    const loadAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Adsense error: ", e);
      }
    };
    loadAds();
  }, []);

  return (
    <div style={{ width: '100%', textAlign: 'center', margin: '20px 0',border:'1px solid black' }}>
      <ins className="adsbygoogle"
     style={{display:'block', textAlign:'center'}}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-9576669115285761"
     data-ad-slot="3162534475"></ins>
    </div>
  );
};

export default AdsComponent;