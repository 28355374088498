// import logo from './logo.svg';
import './App.css';
import AdsComponent from './AdsComponent.js';
import DragAndDropComponent from './dragndrop.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Quick compress</h1>
        <p>Enable compression of selected files less than 20 MB</p>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        
        <DragAndDropComponent />
        <AdsComponent dataAdSlot='3162534475' />
        <h1>Understanding File Compression</h1>
        <p>File compression is a critical process in managing and transferring digital data, especially as file sizes continue to grow with advancements in technology. By reducing the size of files, compression techniques allow for more efficient storage and faster transmission over networks, which is essential for both individual users and large organizations. This discussion will delve into the basics of file compression, its types, benefits, and some common formats and tools used in practice.</p>

        <h2>Types of File Compression</h2>
        <p>File compression involves encoding information using fewer bits than the original representation. At its core, the goal is to eliminate redundancies and reduce the data footprint without losing the essential content of the file. There are two primary types of compression: lossless and lossy.</p>
        
        <h3>Lossless Compression</h3>
        <p>This method compresses data in a way that allows for the original data to be perfectly reconstructed from the compressed data. Lossless compression is essential for applications where the exact preservation of data is necessary, such as text documents and source code files. Common lossless formats include ZIP, PNG for images, and FLAC for audio files. Techniques like Huffman coding and Lempel-Ziv-Welch (LZW) algorithm are widely used in lossless compression.</p>
        
        <h3>Lossy Compression</h3>
        <p>In lossy compression, some data is permanently eliminated to achieve higher compression ratios. This type of compression is typically used for audio, video, and images where a loss of some data might not be noticeable to the end user but significantly reduces the file size. JPEG for images, MP3 for audio, and MPEG for video are popular lossy formats. The key in lossy compression is balancing quality and size to meet user needs.</p>

        <h2>Benefits of File Compression</h2>
        <p>The benefits of file compression are numerous, particularly in terms of storage and speed:</p>
        <ul>
            <li><strong>Reduced Storage Space:</strong> Compressing files saves storage space on hard drives, servers, and cloud storage systems. This efficiency is critical for data centers and enterprises with large amounts of data.</li>
            <li><strong>Faster Transmission:</strong> Compressed files take less time to transfer over the internet or between devices. This speed is crucial for online streaming, website performance, and when sharing files via email or cloud services.</li>
            <li><strong>Cost Efficiency:</strong> By reducing the amount of digital space required and speeding up transmission, compression can lead to significant cost savings, particularly for organizations that handle large amounts of data.</li>
        </ul>

        <h2>Common Compression Formats and Tools</h2>
        <p>Several file compression formats and tools are widely used today, each suited to different types of data and applications:</p>
        <ul>
            <li><strong>JPEG:</strong> JPEG (Joint Photographic Experts Group) is a commonly used method of lossy compression for digital images, particularly for those images produced by digital photography. The degree of compression can be adjusted, allowing a selectable trade-off between storage size and image quality. JPEG compression is based on the discrete cosine transform (DCT), a technique for converting a signal into elementary frequency components. This transformation effectively reduces the amount of data needed to represent an image by discarding information that is less perceptible to the human eye. JPEG files typically use the .jpg or .jpeg file extensions.</li>
            <li><strong>PNG:</strong> PNG (Portable Network Graphics) is a raster-graphics file-format that supports lossless data compression. PNG was created as an improved, non-patented replacement for GIF. PNG uses a filter method and a zlib compression algorithm to compress image data. The filter method predicts the color values of pixels based on the values of adjacent pixels and records the difference. This difference is usually smaller and thus more compressible. PNG supports transparency and is often used for web graphics, logos, and other images where lossless quality is important.</li>
            <li><strong>GIF:</strong> (Graphics Interchange Format) is a bitmap image format that supports up to 8 bits per pixel for each image, allowing a single image to reference its own palette of up to 256 different colors chosen from the 24-bit RGB color space. GIF supports animations and allows a separate palette of 256 colors for each frame. It also supports transparency. GIF images are compressed using the Lempel–Ziv–Welch (LZW) lossless data compression technique to reduce the file size without degrading the visual quality. GIF is widely used for web graphics due to its support for simple animations.</li>
            <li><strong>PDF:</strong> (Portable Document Format) is a file format developed by Adobe in 1992 to present documents, including text formatting and images, in a manner independent of application software, hardware, and operating systems. PDFs encapsulate a complete description of a fixed-layout flat document, including the text, fonts, graphics, and other information needed to display it. PDFs can contain various types of content, such as text, images, and vector graphics, and support features like encryption, digital signatures, and forms. The format is based on the PostScript language and uses a combination of vector graphics and raster images to ensure that documents look the same on all devices.</li>
            {/* <li><strong>MP3 and AAC:</strong> These formats are used for compressing audio files with lossy compression, balancing sound quality against file size efficiently.</li> */}
        </ul>

        <h2>Future of File Compression</h2>
        <p>As digital media continue to evolve, so too does the need for more advanced compression techniques. The future of file compression lies in smarter algorithms that can achieve higher compression ratios with minimal loss of quality. Artificial intelligence and machine learning are beginning to play roles in shaping these algorithms, potentially revolutionizing how data is compressed and transmitted. Moreover, with the rise of 4K video and high-resolution images, the demand for effective compression solutions that can handle increasingly large file sizes without compromising on speed or quality is greater than ever.</p>
      </header>
    </div>
  );
}

export default App;
