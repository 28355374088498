import React, { useState } from 'react';
import { Paper, List, ListItem, ListItemText, Button, Tab, Tabs, Box } from '@mui/material';

function DragAndDropComponent() {
  const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = [...event.dataTransfer.files];
    setFiles(prevFiles => [...prevFiles, ...newFiles.map(file => Object.assign(file))]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const clearFiles = () => {
    setFiles([]);
  };

  const downloadAllFiles = () => {
    files.forEach(file => {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="file type tabs">
          <Tab label="JPEG" />
          <Tab label="PNG" />
          <Tab label="GIF" />
          <Tab label="PDF" />
        </Tabs>
      </Box>
      <Button variant="contained" component="label">
        Upload Files
        <input type="file" hidden multiple onChange={e => setFiles([...e.target.files])} />
      </Button>
      <Button variant="outlined" onClick={clearFiles}>
        Clear Queue
      </Button>
      <Button variant="contained" onClick={downloadAllFiles}>
        Download All
      </Button>
      <Paper elevation={3} onDrop={handleDrop} onDragOver={handleDragOver} style={{ minHeight: 200, border: '2px dashed #ccc', margin: 16 }}>
        <List>
          {files.map((file, index) => (
            <ListItem key={index} style={{ backgroundColor: '#f4f4f4', padding: 16, marginBottom: 8 }}>
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
}

export default DragAndDropComponent;